<template>
  <!-- <div class="containerLogos"> -->
    <!-- <div class="d-flex align-items-start justify-content-between">
      <img
        class="img-fluid img-1"
        src="https://www.cantalive.com.br/selenia.png"
        alt="Logo Logoplaste"
      />
      <img
        class="img-fluid img-2"
        src="@/assets/img/petronas-branco.png"
        alt="Logo Petronas"
      />
      
    </div>
  </div> -->
  <div class="header position-absolute w-100">
      <div class="d-flex justify-content-end align-items-center">
        <img class="img-fluid img-right logo" src="@/assets/img/petronas-branco.png" alt="PETRONAS">
      </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.containerLogos{
  box-sizing: border-box;
  padding: 0 20px;
}
.header {
  padding: 10px 80px;
  margin-top: 0px;
}

.header img{
  width: 70px;
}

.syntium{
  height: 70px;
}
.petronas{
  height: 80px;
}

.img-1{
  max-width: 130px;
}
.img-2{
  max-width: 100px;
}
@media(max-width: 991px){
  .header {
    padding: 0 50px;
  }
  .syntium{
    height: 60px;
  }
  .petronas{
    height: 70px;
  }
}

@media(max-width:767px) {
  .header {
    padding: 0 30px;
    margin-top: 30px;
  }
  .syntium{
    height: 60px;
  }
  .petronas{
    height: 80px;
  }

  .header img{
    width: 70px;
    height: 75px;
  }
}

@media(max-width:575px) {
  .header {
    padding: 0 15px;
    margin-top: 20px;
  }
  .syntium{
    height: 50px;
  }
  .petronas{
    height: 60px;
  }
}



</style>