<template>
    <div>
      <Carregamento v-if="!live" />
      <div  v-else class="home m-0">
        <div class="cover"></div>
             <Logo />
          <div class="container">
            <div class="row row-form">

              <div class="col text-center text-sm-left px-md-3 p-0 mx-auto mx-md-0 mt-5 pt-5">
                <h1 class="mb-0 line1">{{live.waitingTxt.texts[`${lang}`].line1}}</h1>
                <h1 class="mb-0 line2">{{live.waitingTxt.texts[`${lang}`].line2}}</h1>
                <h1 class="mb-0 line3">{{live.waitingTxt.texts[`${lang}`].line3}}</h1>
                <div class="containerSubText mt-4" v-if="live.waitingTxt.texts[`${lang}`].line4">
                  <h1 class="mb-0 line4">{{live.waitingTxt.texts[`${lang}`].line4}}<br class="d-none d-md-block"/>{{live.waitingTxt.texts[`${lang}`].line5}}</h1>
                </div>
                <div class="containerDate d-flex mx-auto mx-sm-0 justify-content-start align-items-center mt-4 flex-column flex-sm-row">
                  <div class="d-flex d-sm-none align-items-center mb-3">
                    <i class="fas fa-calendar-day calendarIcon mr-4"></i>
                    <p class="textDate mb-0 mr-4">{{live.waitingTxt.texts[`${lang}`].textDate}}</p>
                  </div>
                  <div class="d-none d-sm-flex flex-row align-items-center">
                    <i class="fas fa-calendar-day calendarIcon mr-4"></i>
                    <p class="textDate mb-0 mr-4">{{live.waitingTxt.texts[`${lang}`].textDate}}</p>
                  </div>
                  
                  <div class="d-flex" :class="{'flex-column':lang=='pt','flex-row align-items-center':lang=='esp'}">
                    <p class="mb-0 text-center text-sm-left textHour" :class="{'mr-3':lang=='esp'}">{{live.waitingTxt.texts[`${lang}`].textHour}}</p>
                    <p class="mb-0 text-center text-sm-left textTimeZone" v-if="lang=='pt'" style="transform:translateY(-10px) !important">{{live.waitingTxt.texts[`${lang}`].textTimeZone}}</p>
                    <p class="mb-0 text-center text-sm-left textTimeZone" style="line-height:120%" v-else v-html="live.waitingTxt.texts[`${lang}`].textTimeZone"></p>
                  </div>
                  
                </div>
                <!-- <h1 class="text-center text-white m-0" >{{txt.title}}</h1> -->
                <!-- <p class="text-center text-white" >{{txt.date}}</p> -->
                <!-- <p class="text-center text-white small" v-if="lang == 'esp'"  -->
                <!-- style="margin-top: -10px; font-size: 18px;" >*Horario de Brasilia</p> -->
              </div>
            </div>
          </div>
      </div>

        
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';

    import Logo from '@/components/Login/Logo.vue';

    export default {
        name: "waiting",
         components: {
            Logo
        },
        data() {
            return {
                t: '',
                txt: {
                  title: '',
                  date: ''
                }
            }
        },
        computed: {
           ...mapState(['live', 'lang'])
        },
        methods: {
             setStyle() {
                let obj = this.$store.state.live;
                let prefix = obj.login;
                let prefix2 = obj.waitingTxt;

                switch (this.lang) {
                  case 'pt':
                    this.txt.title = prefix2.texts.pt.title
                    this.txt.date = prefix2.texts.pt.date
                    break;
                  case 'esp':
                    this.txt.title = prefix2.texts.esp.title
                    this.txt.date = prefix2.texts.esp.date
                    break;
                  case 'en':
                    this.txt.title = prefix2.texts.en.title
                    this.txt.date = prefix2.texts.en.date
                    break;
                  default:
                    this.txt.title = prefix2.texts.pt.title
                    this.txt.date = prefix2.texts.pt.date
                    break;
                }

                document.body.style.cssText =
                    `background: url(${prefix.body.styles.backgroundImage}) ${prefix.body.styles.backgroundColor};`;


            },
            getLive() {
                this.$store
                    .dispatch('getLive', this.lang)
                    .then(() => {
                        this.setStyle()
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            async redirectToLogin(){
                if(this.live.waiting[`${this.lang}`] == 'false' || !this.live.waiting[`${this.lang}`]){
                    clearInterval(this.t);
                    this.$router.push(`/login/${this.lang}`)
                }
                
            },
            async checkWaiting(){
                this.t = setInterval(() =>{
                    this.$store
                    .dispatch('getLive', this.lang)
                    .then(() => {})
                },60000)
            },
            setLang(){
                // this.$route.params.lang ? this.$store.commit('UPDATE_LANG', this.$route.params.lang) : this.$store.commit('UPDATE_LANG', 'pt')
                // this.$route.params.lang !='pt' ? this.$router.push('/login/pt') : this.$router.push('/login/pt')
            }
        },
        watch: {
           live(){
                this.redirectToLogin();
           }
        },
        created() {
            this.setLang()
            this.getLive()
            this.checkWaiting();
        }
    }
</script>

<style scoped>
.line1 {
  font-family: 'museo-sans-500' !important;
  font-size: 2.25rem;
}
.line2,.line3 {
  font-size: 3rem;
}
.line4, .line5 {
  font-size: 1.5rem;
}

.line2,.line3,.line4,.line5,.textDate {
  color: #fff;
}
.textDate {
  font-family: 'museo-sans-700' !important;
}
.line2, .line3, .line4, .line5,.textHour {
  font-family: 'museo-sans-900' !important;
}
.line1, .textTimeZone,.textHour {
  color: #F9B800;
}
.calendarIcon, .textTimeZone {
  color: #1EA69B;
}

.calendarIcon {
  font-size: 3rem;
}

.textDate {
  font-size: 3.5rem;
}

.textHour {
  font-size: 2.25rem;
}

.textTimeZone {
  font-size: 1.25rem;
  /* transform: translateY(-5px); */
}

.containerDate {
  /* background: #36363641; */
  /* box-shadow: 0 0 10px rgba(0,0,0,0.8); */
  /* border: #1EA69B 5px solid; */
  border-radius: 2px;
  width: fit-content;
  padding: 10px 25px 10px 0;
}

.containerSubText {
  background: #1EA69B;
  width: fit-content;
  padding: 10px 25px;
  border-radius: 2px;
}

.cover {
  background: #00000099;
  position: fixed;  
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}

.home {
  background-image: url('https://stg-guilherme.canta.net.br/img/backgroundLive.png');
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: 100vh;
}
.home .row-form {
  padding: 200px 0;
}
@media(max-width:767px) {
  .line1 {
    font-size: 2rem;
  }
  .line2,.line3 {
    font-size: 2.25rem;
  }
  .line4, .line5 {
    font-size: 1.5rem;
  }   
}
@media(max-width:575px) {
 .home .row-form {
    padding: 80px 25px;
  } 
  .line1 {
    font-size: 1.5rem;
  }
  .line2,.line3 {
    font-size: 1.75rem;
  }
  .line4, .line5 {
    font-size: 1.25rem;
  }   
  .textDate {
    font-size: 2rem;
  }
  .textHour {
    font-size: 2rem;
  }
  .textTimeZone {
    font-size: 1rem;
    /* transform: translateY(-5px); */
  }
  .calendarIcon {
    font-size: 2.5rem;
  }
}
/* @media (max-width: 767px) {
  .home .row-form {
    padding-top: 180px;
  }
}
.home h1 {
  font-family: 'museo-sans-900';
  font-size: 75px;
}
@media (max-width: 1200px) {
  .home h1 {
    font-size: 63px;
  }
}
@media (max-width: 991px) {
  .home h1 {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  .home h1 {
    font-size: 44px;
  }
}
.home p {
  font-family: 'museo-sans';
  font-size: 40px;
}
@media (max-width: 1200px) {
  .home p {
    font-size: 35px;
  }
}
@media (max-width: 991px) {
  .home p {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .home p {
    font-size: 25px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}
.small{
  font-size: 14px;
} */
    /* small {
        font-size: 1.15rem;
        font-family: museo-sans;
    }

    .logoCentro{
        margin: 40px auto 150px auto !important;        
        max-width: 400px;
        z-index: 10;
    }
    .box-text{
        font-family: museo-sans-900;
        color: white;
    }
    .text-center{
        font-size: 40px;
    }
    .data {
        font-family: museo-sans-900;
        font-size: 40px;
        line-height: 35px;
    }
    @media (max-width: 500px) {
        .logoCentro{
            margin: 40px auto 100px auto !important;
            
            width: 300px;
        }
        .text-center{
            font-size: 30px;
        }
        .container{
            margin-top: 50px;
        }
        .data {
            font-size: 20px;
            line-height: 25px;
        }
    } */
</style>